import { Redirect, Route } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

export interface IProps {
  exact?: boolean;
  path: string;
  component?: React.ComponentType<any>;
  restricted?: boolean;
  redirect?: boolean
};

const PublicRoute: React.FC<IProps> = ({ children, restricted, redirect, ...rest }) => {

  if (rest.path === '/') {
    console.info('path', rest.path)
    return (
      <Redirect
        to={{
          pathname: "/login",
          // state: { from: location }
        }}
      />
    )
  }

  return (
    <Route
      {...rest}
      render={() => (children)}
    />
  )
};

export default PublicRoute;