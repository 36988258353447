import moment from 'moment';
import React from 'react'
import { LogoOxone } from '../assets/images';
import { formatNumber } from '../utils/commons';

type refType = {};

interface IProps {
  ref?: any;
  validatorName: string,
  phoneNumber: string,
  voucherCode: string,
  discountValue: string,
  total: number,
}

export const ComponentToPrint = React.forwardRef<any, IProps>((props, ref) => {

  const date = new Date()

  return (
    <div ref={ref} >
      <div
        className='flex '
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: 10,
          fontWeight: 'bold',
        }}
      >
        <LogoOxone fill="#000000" height={25} />
        <br />
        <p><b>Oxone Voucher Event {date.getFullYear().toString()}</b></p>
      </div>
      <br />

      <div>
      </div>
      <div className='flex full-width'>
        <span><b>Tanggal</b></span>
        <span>
          <b>
            {moment(date).locale('id').format('YYYY/MM/DD HH:mm:SS')}
          </b>
        </span>
      </div>
      <br />

      <div className='flex full-width'>
        <span><b>Validator</b></span>
        <span>
          <b>
            {props.validatorName}
          </b>
        </span>
      </div>
      <br />

      <div className='flex full-width'>
        <span><b>No. HP.</b></span>
        <span>
          <b>
            {props.phoneNumber[0] !== '+' ? '+' + props.phoneNumber : props.phoneNumber}
          </b>
        </span>
      </div>
      <br />

      <div className='flex full-width'>
        <span><b>Kode Voucher</b></span>
        <span>
          <b>
            {props.voucherCode}
          </b>
        </span>
      </div>
      <br />

      <div className='flex full-width'>
        <span><b>Nominal Voucher</b></span>
        <span>
          <b>
            Rp{formatNumber(props.discountValue)}
          </b>
        </span>
      </div>
      <br />

      <div className='flex full-width'>
        <span><b>Total Belanja</b></span>
        <span>
          <b>
            Rp{formatNumber(props.total)}
          </b>
        </span>
      </div>
    </div>
  );
});