import React from "react";
import {
  Form,
  Input,
  Layout,
  Row,
  message,
  Modal,
  FormInstance,
  Checkbox,
  Drawer,
  Divider,
  Avatar,
  Col,
  Button,
  DrawerProps,
} from "antd";
import {
  LockOutlined,
  MailOutlined,
  UserOutlined,
  PhoneOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
  DownOutlined,
} from "@ant-design/icons";
import PageHeader from "../components/PageHeader";
import { Link, useHistory, useLocation } from "react-router-dom";
import AppButton from "../components/AppButton";
import Text from "antd/lib/typography/Text";
import { httpRequest } from "../helpers/api";
import Title from "antd/lib/typography/Title";
import { generateFormRules } from "../helpers/formRules";
import { getErrorMessage } from "../helpers/errorHandler";
import useModalAuth from "../hooks/useModalAuth";
import useAuth from "../hooks/useAuth";
import { IHttpResponse } from "../helpers/pagination";
import { IAuthResponsePayload } from "../types/auth.type";
import SidebarMenu from "../components/SidebarMenu";
import useWindowSize from "../hooks/useWindowSize";
import MenuIcon from "../components/MenuIcon";
import { Menu as Ham, X, ChevronRight, ChevronDown } from "react-feather";
import AppFooter from "./AppFooter";
// import useCart from '../hooks/useCart';
// import ModalCart, { } from '../components/ModalCart';
import SignInOrProfile from "../components/SignInOrProfile";
import ModalOrderCreated from "../components/ModalOrderCreated";
import axios from "axios";
import { IUser } from "../types/user.type";
import useGlobalSearch from "../hooks/useGlobalSearch";
import useGlobalConfig from "../hooks/useGlobalConfig";
import { generateInitialFromName } from "../helpers/generator";
import { theme } from "../assets/theme";
import UserAvatar from "../components/UserAvatar";
import useGlobalFilter from "../hooks/useGlobalFilter";
import { appVersion, thisYear } from "../helpers/constant";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { LogoOxone } from "../assets/images";
import jwt from "jsonwebtoken";

const { Content } = Layout;

const headerMenus = [
  {
    key: "/#how-it-works",
    label: "How it Works",
  },
  {
    key: "/#our-locations",
    label: "Our Locations",
  },
  {
    key: "faqs",
    label: "FAQs",
  },
  {
    key: "contact-us",
    label: "Contact Us",
  },
];

const signInMenus = [
  {
    key: "orders",
    label: "My Orders",
  },
  {
    key: "profile",
    label: "My Profile",
  },
  {
    key: "sign-out",
    label: "Sign Out",
  },
];

type AdditionalProps = {
  showPageHeader?: boolean;
  pageHeaderTitle?: string;
  pageHeaderSubtitle?: string;
  showBanner?: boolean;
  hasSidebar?: boolean;
  isHomepage?: boolean;
  componentUnderSubtitle?: any;
};

const AppLayout: React.FC<AdditionalProps> = ({
  children,
  showPageHeader = true,
  pageHeaderTitle,
  pageHeaderSubtitle,
  hasSidebar,
  isHomepage,
  componentUnderSubtitle,
}) => {
  const location = useLocation();
  const { aboutUs, init } = useGlobalConfig();
  const { globalKeyword, setGlobalKeyword } = useGlobalSearch();
  const history = useHistory();
  const { isLoggedIn, login, user, logout, getProfile } = useAuth();
  const { width, isMobile } = useWindowSize();
  // const {
  //   isShowModalCart,
  //   setIsShowModalCart,
  //   carts,
  //   cartInit } = useCart();

  React.useEffect(() => {
    if (isLoggedIn) {
      getProfile();
    }
  }, [isLoggedIn]);

  const [isShowModalOrderCreated, setIsShowModalOrderCreated] =
    React.useState(false);

  const [dataForOrderCreatedModal, setDataForOrderCreatedModal] =
    React.useState({
      email: "",
      locationName: "",
      locationAddress: "",
      locationContact: "",
    });

  const formRef =
    React.useRef<FormInstance<{ email: string; password: string }>>(null);

  const formRefRegister = React.useRef<
    FormInstance<{
      fullName: string;
      phoneNumber: string;
      email: string;
      password: string;
    }>
  >(null);

  const formRefPhone = React.useRef<
    FormInstance<{
      phone: string;
    }>
  >(null);

  const formRefOtp = React.useRef<
    FormInstance<{
      otp: string;
    }>
  >(null);

  const {
    isShowModalLogin,
    setIsShowModalLogin,
    isShowModalRegister,
    setIsShowModalRegister,
  } = useModalAuth();
  const [isLoadingLogin, setIsLoadingLogin] = React.useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = React.useState(false);
  const [isAgreeSignUp, setIsAgreeSignUp] = React.useState(false);
  const [isShowDrawer, setIsShowDrawer] = React.useState(false);
  const { selectedLocation, setIsShowModalSelectLocation } = useGlobalFilter();

  const [isShowModalPhone, setIsShowModalPhone] = React.useState(false);
  const [isLoadingLoginByPhone, setIsLoadingLoginByPhone] =
    React.useState(false);

  const [isShowModalOtp, setIsShowModalOtp] = React.useState(false);
  const [isLoadingOtp, setIsLoadingOtp] = React.useState(false);

  const [sessionId, setSessionId] = React.useState("");
  const [placement, setPlacement] = React.useState<DrawerProps['placement']>('top');
  const [visible, setVisible] = React.useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const toggleDrawer = (value: boolean) => {
    setIsShowDrawer(value);
  };

  // scroll to top
  const refHeader = React.useRef<any>();
  React.useEffect(() => {
    if (!location.hash && refHeader && refHeader.current) {
      refHeader.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  // scroll to top

  React.useEffect(() => {
    init();
  }, []);

  const onSubmitLogin = async (values: { email: string; password: string }) => {
    try {
      setIsLoadingLogin(true);
      const res = await axios.post<IHttpResponse<IAuthResponsePayload>>(
        "auth/signin",
        { email: values.email, password: values.password }
      );

      const resProfile = await axios.get<IHttpResponse<IUser>>("/admin/info/me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + res.data.payload.token,
        },
      });

      setIsLoadingLogin(false);

      login({
        token: res.data.payload.token,
        user: resProfile.data.payload,
      });

      message.success("Login successfully");

      setIsShowModalRegister(false);
      setIsShowModalLogin(false);
    } catch (err) {
      message.error("Login failed. " + getErrorMessage(err));
      setIsLoadingLogin(false);
    }
  };

  const onSubmitRegister = (values: {
    fullName: string;
    phoneNumber: string;
    email: string;
    password: string;
  }) => {
    setIsLoadingRegister(true);
    httpRequest
      .post("auth/register", {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        console.info("res", res.data);
        setIsLoadingRegister(false);

        message.success("Register successfully");

        setIsShowModalRegister(false);
        setIsShowModalLogin(true);
      })
      .catch((err) => {
        message.error(getErrorMessage(err));
        setIsLoadingRegister(false);
      });
  };

  const onSubmitLoginByPhone = (values: { phone: string }) => {
    console.log(values);
    setIsLoadingLoginByPhone(true);
    axios
      .post<IHttpResponse<{ sessionId: string }>>("auth/phone/send-otp", {
        phoneNumber: values.phone,
      })
      .then((res) => {
        setSessionId(res.data.payload.sessionId);

        setIsLoadingLoginByPhone(false);
        message.success("OTP Sent");
        setIsShowModalPhone(false);
        setIsShowModalOtp(true);
      })
      .catch((err) => {
        message.error(getErrorMessage(err));
        setIsLoadingLoginByPhone(false);

        // ByPass Error In Send OTP
        setIsShowModalPhone(false);
        setIsShowModalOtp(true);
        // PLEASE DELETE THIS WHEN WABA IS READY
      });
  };

  const onSubmitOtp = async (values: { otp: string }) => {
    setIsLoadingOtp(true);
    try {
      const submitRes = await axios.post<IHttpResponse<IAuthResponsePayload>>(
        "auth/phone/login/phone-otp",
        {
          otp: values.otp,
          sessionId: sessionId,
        }
      );

      const resProfile = await axios.get<IHttpResponse<IUser>>("/admin/info/me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + submitRes.data.payload.token,
        },
      });

      setIsLoadingLogin(false);

      login({
        token: submitRes.data.payload.token,
        user: resProfile.data.payload,
      });

      message.success("Login successfully");

      setIsShowModalOtp(false);
    } catch (err) {
      message.error("Submit OTP failed. " + getErrorMessage(err));
      setIsLoadingOtp(false);
    }
  };

  const handleClickOpenCart = async () => {
    // if (isLoggedIn) {
    //   setIsShowModalCart(!isShowModalCart)
    //   cartInit()
    // } else {
    //   setIsShowModalLogin(true)
    // }
  };

  const handleSuccessGoogle = async (resp: any) => {
    try {
      console.log("GOOGLE");
      console.log(resp);
      setIsLoadingLogin(true);

      const resLogin = await httpRequest.post<
        IHttpResponse<IAuthResponsePayload>
      >("auth/google/signin", resp);

      const resProfile = await axios.get<IHttpResponse<IUser>>("/admin/info/me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + resLogin.data.payload.token,
        },
      });

      setIsLoadingLogin(false);

      login({
        token: resLogin.data.payload.token,
        user: resProfile.data.payload,
      });

      message.success("Login successfully");

      setIsShowModalRegister(false);
      setIsShowModalLogin(false);
    } catch (err) {
      message.error("Login failed. " + getErrorMessage(err));
      setIsLoadingLogin(false);
    }
  };

  const handleFailGoogle = (err: any) => {
    message.error("Login failed. " + getErrorMessage(err));
  };

  const handleSuccessFacebook = async (resp: any) => {
    try {
      setIsLoadingLogin(true);

      console.log("FACEBOOk");
      console.log(resp);

      const resLogin = await httpRequest.post<
        IHttpResponse<IAuthResponsePayload>
      >("auth/facebook/signin", resp);

      const resProfile = await axios.get<IHttpResponse<IUser>>("/admin/info/me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + resLogin.data.payload.token,
        },
      });

      setIsLoadingLogin(false);

      login({
        token: resLogin.data.payload.token,
        user: resProfile.data.payload,
      });

      message.success("Login successfully");

      setIsShowModalRegister(false);
      setIsShowModalLogin(false);
    } catch (err) {
      message.error("Login failed. " + getErrorMessage(err));
      setIsLoadingLogin(false);
    }
  };

  const renderSignInModal = () => {
    return (
      <Modal
        title={<Title level={3}>Sign In</Title>}
        visible={isShowModalLogin}
        footer={null}
        onCancel={() => {
          setIsShowModalLogin(false);
        }}
        width={isMobile ? "90%" : 400}
      >
        <Form
          ref={formRef}
          layout="vertical"
          name="basic"
          initialValues={{
            email: "",
            password: "",
          }}
          onFinish={onSubmitLogin}
        >
          <Form.Item
            name="email"
            label="Email address"
            rules={generateFormRules("Email address", ["required", "email"])}
          >
            <Input
              size="large"
              type="email"
              prefix={<MailOutlined />}
              placeholder="Email address"
            />
          </Form.Item>

          <Form.Item name="password" label="Password">
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>

          <Text
            style={{
              display: "block",
              textAlign: "right",
              paddingBottom: 20,
            }}
          >
            <Link
              style={{
                textDecoration: "underline",
              }}
              onClick={() => {
                setIsShowModalLogin(false);
              }}
              to="/forgot-password"
            >
              Forgot Password?
            </Link>
          </Text>

          <Form.Item>
            <AppButton
              loading={isLoadingLogin}
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Sign In
            </AppButton>
          </Form.Item>

          <AppButton
            loading={isLoadingLogin}
            size="large"
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              backgroundColor: "green",
              marginBottom: "24px",
            }}
            onClick={() => {
              setIsShowModalLogin(false);
              setIsShowModalPhone(true);
            }}
          >
            WhatsApp
          </AppButton>

          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Login by Google"
            onSuccess={handleSuccessGoogle}
            onFailure={handleFailGoogle}
            render={(props: any) => (
              <Button
                onClick={props.onClick}
                disabled={props.disabled}
                style={{
                  borderRadius: 5,
                  width: "100%",
                  marginBottom: "24px",
                }}
              >
                Login with Google
              </Button>
            )}
          // cookiePolicy={'single_host_origin'}
          />

          <FacebookLogin
            appId="1953881364795280"
            autoLoad={false}
            fields="name,email"
            callback={handleSuccessFacebook}
            buttonStyle={{
              width: "100%",
              height: "42px",
              marginBottom: "24px",
              padding: 0,
            }}
          />

          <Text style={{ display: "block", textAlign: "center" }}>
            Didn't have an account?{" "}
            <Link
              style={{
                color: "#FF4A50",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              onClick={() => {
                setIsShowModalLogin(false);
                setIsShowModalRegister(true);
              }}
              to="#"
            >
              Sign Up
            </Link>
          </Text>
        </Form>
        <Row justify="center" style={{ paddingTop: "10px" }}>
          <Text type="secondary" style={{ fontSize: "10px" }}>
            v{appVersion} · © {thisYear}. Base App
          </Text>
        </Row>
      </Modal>
    );
  };

  const renderSignUpModal = () => {
    return (
      <Modal
        title={<Title level={3}>Sign Up</Title>}
        visible={isShowModalRegister}
        footer={null}
        onCancel={() => {
          setIsShowModalRegister(false);
        }}
        width={isMobile ? "90%" : 500}
      >
        <Form
          ref={formRefRegister}
          layout="vertical"
          name="basic"
          initialValues={{
            fullName: "",
            phoneNumber: "",
            email: "",
            password: "",
          }}
          onFinish={onSubmitRegister}
        >
          <Form.Item
            name="fullName"
            label="Full Name"
            rules={generateFormRules("Full Name", ["required"])}
          >
            <Input
              size="large"
              type="fullName"
              prefix={<UserOutlined />}
              placeholder="Full Name"
            />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={generateFormRules("Phone Number", [
              "required",
              "phoneNumber",
            ])}
          >
            <Input
              size="large"
              type="phoneNumber"
              prefix={<PhoneOutlined />}
              placeholder="Phone Number"
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email address"
            rules={generateFormRules("Email address", ["required", "email"])}
          >
            <Input
              size="large"
              type="email"
              prefix={<MailOutlined />}
              placeholder="Email address"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={generateFormRules("Password", ["required", "password"])}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Checkbox
              onChange={() => setIsAgreeSignUp(!isAgreeSignUp)}
              style={{ fontSize: 12 }}
            >
              I agree to Base App{" "}
              <Link
                target="_blank"
                style={{
                  color: "#FF4A50",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                to="/terms-and-conditions"
              >
                Terms and conditions
              </Link>{" "}
              &{" "}
              <Link
                target="_blank"
                style={{
                  color: "#FF4A50",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <AppButton
              disabled={!isAgreeSignUp}
              loading={isLoadingRegister}
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            // onClick={() => formRefRegister.current?.submit()}
            >
              Sign Up
            </AppButton>
          </Form.Item>

          <Text style={{ display: "block", textAlign: "center" }}>
            Already have an account?{" "}
            <Link
              style={{
                color: "#FF4A50",
                fontWeight: "bold",
                textDecoration: "none",
              }}
              onClick={() => {
                setIsShowModalRegister(false);
                setIsShowModalLogin(true);
              }}
              to="#"
            >
              Sign In
            </Link>
          </Text>
        </Form>
      </Modal>
    );
  };

  const renderPhoneModal = () => {
    return (
      <Modal
        title={<Title level={3}>Login with Whatsapp</Title>}
        visible={isShowModalPhone}
        footer={null}
        onCancel={() => {
          setIsShowModalPhone(false);
        }}
        width={isMobile ? "90%" : 500}
      >
        <Form
          ref={formRefPhone}
          layout="vertical"
          name="basic"
          initialValues={{
            phone: "",
          }}
          onFinish={onSubmitLoginByPhone}
        >
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={generateFormRules("Phone Number", [
              "required",
              "phoneNumber",
            ])}
          >
            <Input
              size="large"
              type="number"
              prefix={<UserOutlined />}
              placeholder="Phone Number"
            />
          </Form.Item>

          <Form.Item>
            <AppButton
              loading={isLoadingLoginByPhone}
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Send OTP
            </AppButton>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const renderOtpModal = () => {
    return (
      <Modal
        title={<Title level={3}>Enter OTP Sent to Your Whatsapp</Title>}
        visible={isShowModalOtp}
        footer={null}
        onCancel={() => {
          setIsShowModalOtp(false);
        }}
        width={isMobile ? "90%" : 500}
      >
        <Form
          ref={formRefOtp}
          layout="vertical"
          name="basic"
          initialValues={{
            otp: "",
          }}
          onFinish={onSubmitOtp}
        >
          <Form.Item
            name="otp"
            label="OTP Sent"
            rules={generateFormRules("OTP", ["required"])}
          >
            <Input
              size="large"
              type="name"
              prefix={<UserOutlined />}
              placeholder="OTP"
            />
          </Form.Item>

          <Form.Item>
            <AppButton
              loading={isLoadingOtp}
              size="large"
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Submit
            </AppButton>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const renderAvatar = () => {
    return isLoggedIn && user ? (
      <Button
        type="text"
        onClick={showDrawer}
      >
        <Avatar
          style={{ color: '#fff', backgroundColor: '#faad14' }}
        >
          {user.name ? user.name.substring(0, 1) : '-'}
        </Avatar>
        <DownOutlined style={{ color: '#fff' }} />
      </Button>
    ) : false
  }

  return (
    <Layout className="layout">
      {showPageHeader && (
        <header ref={refHeader} className="header-app">
          <Drawer
            closable={false}
            placement="top"
            onClose={() => toggleDrawer(false)}
            visible={isShowDrawer}
          >
            <Link
              className="drawer-nav"
              to="#"
              onClick={() => {
                toggleDrawer(false);
              }}
            >
              {/* <img
              alt="logo-app"
              src={aboutUs.logo || "/images/logo-app.svg"}
              height={40}
              style={{ maxHeight: 45, maxWidth: 200 }}
            /> */}
              <LogoOxone className="" fill="#000000" />
              <X onClick={() => toggleDrawer(false)} />
            </Link>
            <div className="drawer-menu">
              <Link to="/">Home</Link>
              {headerMenus.map((item) => (
                <Link key={item.key} to={item.key}>
                  {item.label}
                </Link>
              ))}
            </div>

            <Divider />

            {/* TODO: Get auth status */}

            <div className="user-info-container">
              {isLoggedIn ? (
                <div>
                  <div className="user-info-wrapper">
                    <Avatar
                      style={{
                        backgroundColor: "#e5e9ec",
                        verticalAlign: "middle",
                      }}
                      size="large"
                    >
                      {generateInitialFromName(user.name)}
                    </Avatar>
                    <div className="user-info">
                      <span>Welcome back,</span>
                      <h4>{user.name}</h4>
                    </div>
                  </div>
                  <div className="user-menu">
                    {signInMenus.map((item) =>
                      item.key === "sign-out" ? (
                        <Link
                          to="#"
                          key={item.key}
                          onClick={() => {
                            Modal.confirm({
                              title: "Confirmation",
                              content: "Are you sure want to logout?",
                              onOk: () => {
                                logout();
                                history.replace({ pathname: "/" });
                              },
                              okText: `Yes, I'm sure`,
                              okType: "primary",
                              width: isMobile ? "90%" : undefined,
                            });
                          }}
                        >
                          {item.label}
                        </Link>
                      ) : (
                        <Link key={item.key} to={item.key}>
                          {item.label}
                        </Link>
                      )
                    )}
                  </div>

                  <button
                    className="btn"
                    style={{
                      width: "100%",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                    onClick={() => history.push("/menu")}
                  >
                    <MenuIcon color="#fff" />
                    <span
                      style={{
                        flex: 1,
                        justifyContent: "flex-start",
                        textAlign: "left",
                      }}
                    >
                      Order Now
                    </span>
                    <ChevronRight color="#fff" />
                  </button>
                </div>
              ) : (
                <div className="user-info-signin-wrapper">
                  <p>Didn’t have account?</p>
                  <p>Create your account now</p>
                  <button
                    onClick={() => setIsShowModalRegister(true)}
                    className="btn"
                    style={{ width: "100%" }}
                  >
                    Create account
                  </button>
                  <p>or</p>
                  <button
                    onClick={() => setIsShowModalLogin(true)}
                    className="btn"
                    style={{ width: "100%" }}
                  >
                    Sign In
                  </button>
                </div>
              )}

              <div className="menu-footer-container">
                <div className="footer-social-media-wrap">
                  <a href={aboutUs.instagramLink}>
                    <img alt="instagram" src="images/instagram.svg" />
                  </a>
                  <a href={aboutUs.twitterLink}>
                    <img alt="twitter" src="images/twitter.svg" />
                  </a>
                  <a href={aboutUs.facebookLink}>
                    <img alt="facebook" src="images/facebook.svg" />
                  </a>
                </div>
              </div>
            </div>
          </Drawer>

          <div className="header-homepage container-app">
            {width <= 1199 ? (
              <>
                {/* <button
                onClick={() => toggleDrawer(true)}
                className="btn-sidebar"
              >
                <Ham />
              </button> */}

                <Link className="logo-wrapper" to="/">
                  <LogoOxone fill="#ffffff" className="logo-app" />
                  {/* <img alt="logo-app" src="/images/logo-app.svg" height={40} /> */}
                </Link>

                {renderAvatar()}

                {/* {isMobile &&
              (location.pathname === "/menu" ||
                location.pathname.includes("/profile") ||
                location.pathname === "/orders") ? (
                <div style={{ marginLeft: 10 }}>
                  {isLoggedIn ? (
                    <UserAvatar />
                  ) : (
                    <AppButton
                      onClick={() => setIsShowModalLogin(true)}
                      loading={isLoadingLogin}
                      size="small"
                      type="primary"
                      style={{ borderRadius: 20 }}
                    >
                      Sign In
                    </AppButton>
                  )}
                </div>
              ) : location.pathname === "/menu" ? (
                <button className="btn" onClick={handleClickOpenCart}>
                  <ShoppingCartOutlined
                    size={18}
                    color="#fff"
                    style={{ marginRight: 10 }}
                  />
                </button>
              ) : (
                <Link to="/menu" className="btn-menu">
                  <MenuIcon color="#FFF" />
                </Link>
              )} */}
                {/* <span>Cart · {carts.length}</span> */}
              </>
            ) : (
              <>
                <Link className="logo-wrapper" to="/">
                  {/* <img alt="logo-app" src="/images/logo-app.svg" height={40} /> */}
                  <LogoOxone fill="#ffffff" className="logo-app" />
                </Link>

                {renderAvatar()}

                {/* {location.pathname.includes("/menu") ? (
                <div
                  className="homepage-menu"
                  style={{ gap: 20, width: "78%" }}
                >
                  <Form
                    name="form"
                    layout="vertical"
                    initialValues={{
                      search: "",
                    }}
                    autoComplete="off"
                    style={{ flex: 1 }}
                  >
                    <Form.Item
                      name="search"
                      rules={[]}
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        style={{ borderRadius: 50 }}
                        size="large"
                        placeholder="What are you looking for?"
                        prefix={<SearchOutlined />}
                        onChange={(e) => setGlobalKeyword(e.target.value)}
                        value={globalKeyword}
                      />
                    </Form.Item>
                  </Form>

                  <button className="btn" onClick={handleClickOpenCart}>
                    <ShoppingCartOutlined
                      size={18}
                      color="#fff"
                      style={{ marginRight: 10 }}
                    />
                    
                  </button>

                  <SignInOrProfile />
                </div>
              ) : (
                <div className="homepage-menu">
                  {headerMenus.map((menu) => (
                    <Link to={menu.key} key={menu.key}>
                      {menu.label}
                    </Link>
                  ))}

                  <button className="btn" onClick={() => history.push("/menu")}>
                    <MenuIcon size={18} color="#fff" />
                    <span style={{ marginLeft: 5 }}>Menu</span>
                  </button>

                  <SignInOrProfile />
                </div>
              )} */}
                {/* <span>Cart · {carts.length}</span> */}
              </>
            )}
          </div>
        </header>
      )}

      {/* {showPageHeader && !!pageHeaderTitle && (
        <PageHeader
          title={pageHeaderTitle}
          subtitle={pageHeaderSubtitle}
          componentUnderSubtitle={componentUnderSubtitle}
        />
      )} */}

      <Row
        className="content-app"
        style={
          hasSidebar && !isMobile
            ? { paddingLeft: 100, paddingRight: 100, background: "#F9FAFA" }
            : undefined
        }
      >
        {hasSidebar && !isMobile && <SidebarMenu />}

        <Content
          className="layout-content"
          style={
            hasSidebar && !isMobile
              ? {
                padding: "0 20px",
                width: "65%",
                borderRadius: 10,
                minHeight: 500,
                background:
                  isHomepage ||
                    location.pathname.includes("/menu") ||
                    location.pathname.includes("/contact-us")
                    ? "#FFFFFF"
                    : "#F9FAFA",
              }
              : hasSidebar && isMobile
                ? {
                  width: "100%",
                  borderRadius: 10,
                  minHeight: 500,
                  background:
                    isHomepage ||
                      location.pathname.includes("/menu") ||
                      location.pathname.includes("/contact-us")
                      ? "#FFFFFF"
                      : "#F9FAFA",
                }
                : {
                  width: "100%",
                  padding: isHomepage ? 0 : 15,
                  minHeight: 500,
                  background:
                    isHomepage ||
                      location.pathname.includes("/menu") ||
                      location.pathname.includes("/contact-us")
                      ? "#FFFFFF"
                      : "#F9FAFA",
                }
          }
        >
          {children}
        </Content>
      </Row>

      {/* {renderSignInModal()}

      {renderSignUpModal()}

      {renderPhoneModal()}

      {renderOtpModal()} */}

      {/* <ModalCart
        onCheckoutSuccess={(item) => {
          setDataForOrderCreatedModal(item);
          setIsShowModalOrderCreated(true);
        }}
      /> */}

      {/* <ModalOrderCreated
        {...dataForOrderCreatedModal}
        visible={isShowModalOrderCreated}
        onClose={() => {
          setIsShowModalOrderCreated(false);
        }}
      /> */}

      <Drawer
        title="Account"
        placement={placement}
        closable={false}
        onClose={onClose}
        visible={visible}
        key={placement}
        height={250}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Text
            style={{
              color: '#000',
              marginBottom: 30,
            }}
          >
            Hi, {user && user.name ? user.name : '-'}
          </Text>

          <Link style={{
            color: '#000',
          }} to={'profile'}>
            Profile
          </Link>

          <Divider />

          <Link
            style={{
              color: '#000',
            }}
            to="#"
            onClick={() => {
              Modal.confirm({
                title: "Confirmation",
                content: "Apakah anda ingin logout?",
                onOk: () => {
                  logout();
                  history.replace({ pathname: "/login" });
                },
                okText: `Ya`,
                cancelText: `Batal`,
                okType: "primary",
                width: isMobile ? "90%" : undefined,
              });
            }}
          >
            Logout
          </Link>
        </div>
      </Drawer>

      <AppFooter />
    </Layout>
  );
};

export default AppLayout;
