import moment from 'moment';
import wNumb from "wnumb";

export const previewText = (value: string, maxChar: number) => {
  if (!value) {
    return "-";
  }
  if (value?.length > maxChar) {
    return value.substring(0, maxChar) + "...";
  }
  return value;
};

export const formatNumber = (
  value: any,
  isCurrency: boolean = false,
  woSeparator: boolean = false
) => {
  if (!value) {
    return 0;
  }
  if (isCurrency) {
    return wNumb({
      mark: ",",
      thousand: ".",
      prefix: "₱ ",
      suffix: "",
    }).to(parseInt(value));
  }
  if (woSeparator) {
    return wNumb({
      mark: "",
      thousand: "",
      prefix: "",
      suffix: "",
    }).to(parseInt(value));
  }
  return wNumb({
    mark: ",",
    thousand: ".",
    prefix: "",
    suffix: "",
  }).to(parseInt(value));
};

export const deformatNumber = (value: any) => {
  if (!value) {
    return 0;
  }
  return wNumb({
    mark: ",",
    thousand: ".",
    prefix: "",
    suffix: "",
  }).from(value);
};

export const formatDate = (date: Date | string) => {
  return moment(date).locale('id').subtract(1, 'days').format('DD MMMM YYYY');
}
