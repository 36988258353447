import React from 'react'

type Props = {
  size?: number
  color?: string
}

const MenuIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg width={size || 24} height={size || 24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.7751 15.3718C20.7751 13.0529 19.8539 10.829 18.2142 9.1893C16.5745 7.54959 14.3506 6.62842 12.0317 6.62842C9.71283 6.62842 7.48892 7.54959 5.84921 9.1893C4.20951 10.829 3.28833 13.0529 3.28833 15.3718" stroke={color || '#FF4A50'} strokeWidth="2" strokeLinejoin="round" />
      <circle cx="12.0318" cy="3.71397" r="1.94298" fill={color || '#FF4A50'} />
      <path d="M21.7468 17.3145H2.31702C2.70561 19.646 4.42191 20.2289 5.23148 20.2289H18.8323C21.1639 20.2289 21.7468 18.2859 21.7468 17.3145Z" stroke={color || '#FF4A50'} strokeWidth="2" strokeLinejoin="round" />
    </svg>
  )
}

export default MenuIcon;