import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Homepage from "../screens";
import TermAndCondition from "../screens/TermAndConditions";
import ContactUs from "../screens/ContactUs";
import ForgotPassword from "../screens/ForgotPassword";
import ResetPassword from "../screens/ResetPassword";
import MyAccount from "../screens/customers/MyAccount";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import FAQ from "../screens/FAQ";
import VerifyEmail from "../screens/VerifyEmail";
import Menus from "../screens/menus";
import VoucherValidator from "../screens/voucherValidator";
import MyOrders from "../screens/customers/MyOrder";
import EditProfile from "../screens/customers/Edit";
import ChangePassword from "../screens/customers/ChangePassword";
import ComponentScreen from "../screens/components/ComponentScreen";
import AuthScreen from "../screens/Auth";

const RootNavigator = () => {
  return (
    <Router>
      <Switch>
        {/* Public Route */}
        <PublicRoute exact path="/" restricted={true}>
          <AuthScreen />
        </PublicRoute>
        <PublicRoute exact path="/login" restricted={true}>
          <AuthScreen />
        </PublicRoute>

        {/* <PublicRoute exact path="/menu" restricted={true}>
          <Menus />
        </PublicRoute> */}

        <PublicRoute exact path="/forgot-password" restricted={true}>
          <AuthScreen />
        </PublicRoute>
        <PublicRoute exact path="/reset-password" restricted={true}>
          <AuthScreen />
        </PublicRoute>
        <PublicRoute exact path="/reset-password/:sessionId" restricted={true}>
          <AuthScreen />
        </PublicRoute>
        <PublicRoute exact path="/verify-email/:sessionId" restricted={true}>
          <VerifyEmail />
        </PublicRoute>

        {/* <PublicRoute exact path="/faqs" restricted={true}>
          <FAQ />
        </PublicRoute>
        <PublicRoute exact path="/privacy-policy" restricted={true}>
          <PrivacyPolicy />
        </PublicRoute>
        <PublicRoute exact path="/terms-and-conditions" restricted={true}>
          <TermAndCondition />
        </PublicRoute>
        <PublicRoute exact path="/contact-us" restricted={true}>
          <ContactUs />
        </PublicRoute> */}

        <PrivateRoute exact path="/profile">
          <MyAccount />
        </PrivateRoute>
        {/* <PrivateRoute exact path="/profile/edit">
          <EditProfile />
        </PrivateRoute>
        <PrivateRoute exact path="/profile/change-old-password">
          <ChangePassword />
        </PrivateRoute>
        <PrivateRoute exact path="/orders">
          <MyOrders />
        </PrivateRoute>
        <PublicRoute exact path="/components">
          <ComponentScreen />
        </PublicRoute> */}
        <PrivateRoute exact path="/voucher-event">
          <VoucherValidator />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default RootNavigator;
