import { Footer } from "antd/lib/layout/layout";
import { appVersion } from '../helpers/constant';

export default function AppFooter() {
  return (
    <Footer className="footer-container">
      <div className="footer-wrapper">
        <p>{appVersion} - ©2022 Oxone All rights reserved</p>
      </div>
    </Footer>
  );
}
