import axios from 'axios';
import { createGlobalState } from 'react-hooks-global-state';
import { IAboutUs, initialAboutUs } from '../types/aboutUs.type';
import { message } from 'antd';
import { getErrorMessage } from '../helpers/errorHandler';
import { IHttpResponse, IPayloadPagination } from '../helpers/pagination';
import { ILocation } from '../types/location.type';
import { ITag } from '../types/tag.type';

type State = {
  isLoadingAboutUs: boolean;
  aboutUs: IAboutUs;

  // isLoadingLocation: boolean;
  // locations: ILocation[];

  // isLoadingTag: boolean;
  // tags: ITag[];
  // allergens: ITag[];

  isShowModalCart: boolean;
};

const initialState: State = {
  isLoadingAboutUs: false,
  aboutUs: {
    ...initialAboutUs,
  },

  // isLoadingLocation: false,
  // locations: [],

  // isLoadingTag: false,
  // tags: [],
  // allergens: [],

  isShowModalCart: false,
};
const { useGlobalState } = createGlobalState(initialState);

export default function useGlobalConfig() {
  const [isLoadingAboutUs, setisLoadingAboutUs] =
    useGlobalState('isLoadingAboutUs');
  const [aboutUs, setAboutUs] = useGlobalState('aboutUs');

  // const [isLoadingLocation, setisLoadingLocation] =
  //   useGlobalState('isLoadingLocation');
  // const [locations, setLocations] = useGlobalState('locations');

  // const [isLoadingTag, setisLoadingTag] = useGlobalState('isLoadingTag');
  // const [tags, setTags] = useGlobalState('tags');
  // const [allergens, setAllergens] = useGlobalState('allergens');

  const init = () => {
    // getAboutUs();
    // getLocations();
    // getTags();
  };

  const getAboutUs = () => {
    setisLoadingAboutUs(true);
    axios
      .get<IHttpResponse<IAboutUs>>('aboutus')
      .then((res) => {
        setAboutUs(res.data.payload);
        setisLoadingAboutUs(false);
      })
      .catch((err) => {
        message.error(getErrorMessage(err));
        setisLoadingAboutUs(false);
      });
  };

  // const getLocations = () => {
  //   setisLoadingLocation(true);
  //   axios
  //     .get<IHttpResponse<IPayloadPagination<ILocation>>>('locations')
  //     .then((res) => {
  //       setLocations(res.data.payload.results);
  //       setisLoadingLocation(false);
  //     })
  //     .catch((err) => {
  //       message.error(getErrorMessage(err));
  //       setisLoadingLocation(false);
  //     });
  // };

  // const getTags = () => {
  //   setisLoadingTag(true);
  //   axios
  //     .get<IHttpResponse<IPayloadPagination<ITag>>>('tags?isPublished=1')
  //     .then((res) => {
  //       setTags(res.data.payload.results.filter((item) => item.type === 'tag'));
  //       setAllergens(
  //         res.data.payload.results.filter((item) => item.type === 'allergen')
  //       );
  //       setisLoadingTag(false);
  //     })
  //     .catch((err) => {
  //       message.error(getErrorMessage(err));
  //       setisLoadingTag(false);
  //     });
  // };

  return {
    init,
    isLoadingAboutUs,
    aboutUs,
    getAboutUs,
    // isLoadingLocation,
    // locations,
    // getLocations,
    // isLoadingTag,
    // tags,
    // allergens,
    // getTags,
  };
}
