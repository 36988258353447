export interface IAboutUs {
  aboutusId: string;
  logo: string;
  officeTitle: string;
  officeAddress: string;
  officeCoords: {
    lat: number;
    lng: number;
  };
  contact: string;
  officeEmail: string;
  instagramLink: string;
  twitterLink: string;
  youtubeLink: string;
  facebookLink: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export interface FetchAllAboutUsResponse extends IAboutUs {
  payload: IAboutUs
}

export const initialAboutUs: IAboutUs = {
  aboutusId: '',
  logo: '',
  officeTitle: '',
  officeAddress: '',
  officeCoords: {
    lat: 0,
    lng: 0,
  },
  contact: '',
  officeEmail: '',
  instagramLink: '',
  twitterLink: '',
  youtubeLink: '',
  facebookLink: '',
  createdAt: new Date(),
  updatedAt: new Date(),
}