import clsx from "clsx";
import { useState, useEffect, useMemo, useRef } from "react";
import { Form, Input, Button, message } from "antd";
import { UserOutlined } from "@ant-design/icons";

import AppLayout from "../../layout/AppLayout";
import { ModalWithStatus } from "../../components";
import { dataRole } from "../../constants";
import { deformatNumber, formatDate, formatNumber } from "../../utils/commons";
import "../../assets/voucher-validator.less";
import useAuth from '../../hooks/useAuth';
import { EAdminRole } from '../../types/user.type';
import { httpRequest } from '../../helpers/api';
import { IHttpResponse } from '../../helpers/pagination';
import { VoucherGenerateProperties, VoucherProperties, VoucherValidatorProperties } from '../../types/voucher.type';
import { generateQueryString } from '../../helpers/generateQueryString';
import { getErrorMessage } from '../../helpers/errorHandler';
import { useReactToPrint } from 'react-to-print';
import { LogoOxone } from '../../assets/images';
import moment from 'moment';
import { ComponentToPrint } from '../../components/PrintOut';

const pageStyle = `
  @page {
    size: 80mm 100mm;
    margin: 0;
  }
  @media print {
    html, body {
      width: 80mm;
      height: 100mm;
      margin: 0 !important;
      padding: 0 !important;
      overflow: hidden;
    }
  }
`;

export default function VoucherValidator() {
  const { useForm, Item } = Form;
  const { user } = useAuth()
  const [form] = useForm();
  const [activeNav, setActiveNav] = useState<number>(0);
  const [dataForm, setDataForm] = useState<any>({});
  const [voucherCode, setVoucherCode] = useState<string | null>("PRJ12345");
  const [voucherValue, setVoucherValue] = useState<any>(200000);
  const [voucherValidUntil, setVoucherValidUntil] = useState<string>('');
  const [isClaimSuccess, setClaimSuccess] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loadingUseVoucher, setLoadingUseVoucher] = useState<boolean>(false);
  const [errorClaim, setErrorClaim] = useState<string>(
    "Kode voucher tidak ditemukan"
  );
  const [showModalGenerated, setShowModalGenerated] = useState<boolean>(false);
  const [showModalStatus, setShowModalStatus] = useState<boolean>(false);
  const dataNav = useMemo(() => ["Voucher Generator", "Voucher Validator"], []);
  const [dataTerm, setDataTerm] = useState<string[]>([])
  const [currentUseVoucher, setCurrentUseVoucher] = useState<VoucherProperties & { voucherGenerate: VoucherGenerateProperties } | undefined>(undefined)
  const [loadingPrint, setLoadingPrint] = useState<boolean>(false)

  let componentRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle,
    onBeforePrint: () => {
      setLoadingPrint(true)
    },
    onAfterPrint: () => {
      setLoadingPrint(false)
      handleCloseModal()
    },
    onPrintError: (e: any) => {
      console.error('1.1 failed to print', e)
      console.error('1.2 failed to print', JSON.stringify(e))
    },
    documentTitle: `OXONE-VOUCHER-${voucherCode}-${moment().locale('id').format('YYYY/MM/DD HH:mm:SS')}-${user.name.toUpperCase()}`
  });

  const role = useMemo(() => user.role, [user]);

  const date = new Date()

  const configureRole = () => {
    switch (role) {
      case EAdminRole.spg_voucher_all:
      case EAdminRole.spg_voucher_generate:
        return setActiveNav(0);
      case EAdminRole.spg_voucher_validate:
        return setActiveNav(1);
      default:
        break;
    }
  };

  const dataGenerator = useMemo(
    () => [
      {
        label: "Nomor HP Pelanggan",
        field: "noHp",
        isNumeric: true,
        woSeparator: true,
        prefix: "+62",
        placeholder: "Tulis disini",
        info: "Tulis tanpa awalan +62 atau 0",
      },
      {
        label: "Nomor Invoice",
        field: "noInvoice",
        placeholder: "Tulis disini",
      },
      {
        label: "Total Belanja",
        field: "total",
        placeholder: "Tulis disini",
        isNumeric: true,
        prefix: "Rp",
        info: "Minimal total belanja Rp 2.000.000",
      },
    ],
    []
  );
  const dataValidator = useMemo(
    () => [
      {
        label: "Kode Voucher",
        field: "voucher",
        placeholder: "Tulis disini",
      },
      {
        label: "Total Belanja",
        field: "total",
        placeholder: "Tulis disini",
        isNumeric: true,
        prefix: "Rp",
      },
    ],
    []
  );

  const parseButtonContent = useMemo(() => {
    switch (activeNav) {
      case 1:
        return "Cek Voucher";
      default:
        return "Generate Voucher";
    }
  }, [activeNav]);

  const parseContentForm = () => {
    let dataQuestion = [];
    switch (activeNav) {
      case 1:
        dataQuestion = [...dataValidator]?.map((item) => ({ ...item }));
        break;
      default:
        dataQuestion = [...dataGenerator]?.map((item) => ({ ...item }));
        break;
    }
    return dataQuestion?.map((item: any, index: number) => (
      <Form.Item
        key={index}
        label={item?.label}
        className="input-container"
        name={`${item?.field}-${index}`}
      >
        <Input
          name={`${item?.field}-${index}`}
          placeholder={item?.placeholder}
          className="input-app"
          allowClear
          prefix={
            item?.isNumeric ? (
              <span
                className={clsx("text-lighten", {
                  hide: !dataForm?.[item?.field],
                  "mr-1": dataForm?.[item?.field],
                })}
              >
                {item?.prefix}{" "}
              </span>
            ) : undefined
          }
          value={
            item?.isNumeric && !item?.woSeparator
              ? dataForm?.[item?.field]
                ? formatNumber(dataForm?.[item?.field] || 0)
                : ""
              : dataForm?.[item?.field] || ""
          }
          onChange={({ target: { value } }) =>
            onChangeHandler(
              item?.field,
              item?.isNumeric ? deformatNumber(value) : value
            )
          }
        />
        {item?.info && <p className="info">{item?.info}</p>}
      </Form.Item>
    ));
  };

  const useVoucherHandler = async () => {
    setLoadingUseVoucher(true)
    try {
      await httpRequest.post<IHttpResponse<VoucherProperties>>('/voucher/event/use-voucher', {
        voucherCode: voucherCode,
        totalTransaction: dataForm['total']
      })

      setClaimSuccess(true);
      setShowModalGenerated(false);
      setShowModalStatus(true);
    } catch (error) {
      console.error('error validate')
    } finally {
      setLoadingUseVoucher(false)
    }
  };

  const parseContentModal = useMemo(() => {
    let content, title, state;
    if (activeNav === 0) {
      state = "success";
      title = "Voucher berhasil dibuat";
      content = (
        <>
          <div className="py-6 border-bot">
            <p className="m-0">Syarat & ketentuan voucher</p>
            <ul className="terms">
              {dataTerm?.map((item) => (
                <li dangerouslySetInnerHTML={{ __html: item }} />
              ))}
            </ul>
          </div>
          <div className="py-6 border-bot">
            <p className="m-0 text-title-voucher">Kode Voucher</p>
            <div className="voucher-container">
              <h3 className="m-0 voucher-code">{voucherCode}</h3>
            </div>
            <p className="text-valid text-lighten">Valid sampai 19 Juli 2022</p>
          </div>
          <p className="m-0 py-6 text-center text-lighten">
            Silahkan cek WhatsApp ke nomor{" "}
            <span className="font-bold">+62{dataForm['noHp']}</span> untuk melihat detail
            Voucher
          </p>
        </>
      );
    } else {
      state = "warning";
      title = "Kode voucher valid";
      content = (
        <>
          <div className="py-6">
            <p className="m-0 text-center">
              Kode voucher <span className="font-bold">{voucherCode}</span> berhasil
              divalidasi
            </p>
            <p className="m-0 text-title-voucher">Nominal Voucher</p>
            <div className="voucher-container">
              <h3 className="m-0 voucher-code">
                Rp{formatNumber(voucherValue)}
              </h3>
            </div>
            <p className="text-valid text-lighten">Valid sampai {voucherValidUntil}</p>
          </div>
          <Item className="button-container">
            <Button
              className="button-app"
              onClick={useVoucherHandler}
              loading={loadingUseVoucher}
            >
              Pakai Voucher?
            </Button>
          </Item>
        </>
      );
    }
    return { content, title, state };
  }, [activeNav, voucherCode, dataTerm, useVoucherHandler]);

  const parseContentModalStatus = useMemo(() => {
    if (isClaimSuccess) {
      return (
        <>
          <div className="py-6">
            <p className="m-0 text-center">
              Kode voucher <span className="font-bold">{voucherCode}</span> berhasil
              divalidasi
            </p>
            <p className="m-0 text-title-voucher">Nominal Voucher</p>
            <div className="voucher-container">
              <h3 className="m-0 voucher-code">
                Rp {formatNumber(voucherValue)}
              </h3>
            </div>
            <p className="text-valid text-lighten">Valid sampai {voucherValidUntil}</p>
          </div>
          <Item className="button-container">
            <Button
              className="button-app"
              onClick={handlePrint}
              loading={loadingPrint}
            >
              Print Voucher
            </Button>
          </Item>
        </>
      );
    } else {
      return <p className="m-0 py-6 text-center">{errorClaim}</p>;
    }
  }, [isClaimSuccess, errorClaim]);

  const disabledSubmitButton = useMemo(() => {
    if (activeNav === 0) {
      return (
        !dataForm?.noHp ||
        String(dataForm?.noHp)?.length < 8 ||
        !dataForm?.noInvoice ||
        !dataForm?.total ||
        dataForm?.total < 2000000
      );
    } else if (activeNav === 1) {
      return !dataForm?.voucher || !dataForm?.total;
    }
  }, [dataForm, activeNav]);

  const onChangeHandler = (field: string, value: any) => {
    setDataForm({ ...dataForm, [field]: value });
  };

  const onSubmitHandler = async () => {
    setLoadingSubmit(true)
    setCurrentUseVoucher(undefined)
    try {
      if (activeNav === 0) {
        const resGenerate = await httpRequest.post<IHttpResponse<VoucherProperties>>('/voucher/event/generate', {
          phoneNumber: '62' + dataForm['noHp'],
          invoiceId: dataForm['noInvoice'],
          totalTransaction: dataForm['total']
        })

        if (resGenerate.data && resGenerate.data.payload) {
          const resultVoucher = resGenerate.data.payload

          setVoucherCode(resultVoucher.voucherCode)
          setVoucherValidUntil(formatDate(resultVoucher.validEndAt))

          setDataTerm([
            `Selamat anda mendapatkan voucher sebesar <span class='font-bold'>Rp${formatNumber(resultVoucher.discountValue)}</span>`,
            `Minimum belanja Rp${formatNumber(resultVoucher.requirement.minTotalTransaction)}`,
            `Voucher hanya bisa digunakan satu kali`,
            `Pemakaian voucher khusus untuk Jakarta Fair Kemayoran ${date.getFullYear()}`,
          ])

          setShowModalGenerated(true)
        }
      } else if (activeNav === 1) {
        const resValidate = await httpRequest.get<IHttpResponse<VoucherProperties & { voucherGenerate: VoucherGenerateProperties }>>('/voucher/event/validate' + generateQueryString({
          voucherCode: dataForm['voucher'],
          totalTransaction: dataForm['total'],
        }))

        if (resValidate) {
          setVoucherCode(resValidate.data.payload.voucherCode)
          setVoucherValidUntil(formatDate(resValidate.data.payload.validEndAt))
          setVoucherValue(resValidate.data.payload.discountValue)
          setShowModalGenerated(true)

          setCurrentUseVoucher(resValidate.data.payload)
        }
      }

    } catch (error) {
      setShowModalStatus(true)
      setClaimSuccess(false)
      setErrorClaim(getErrorMessage(error))
    } finally {
      setLoadingSubmit(false)
    }
  };

  useEffect(() => {
    configureRole();
  }, [role]);

  useEffect(() => {
    setDataForm({});
  }, [activeNav]);

  const handleCloseModal = () => {
    console.info('clear form', form.getFieldValue)
    setShowModalGenerated(false)
    setShowModalStatus(false)
    setVoucherCode('')
    setVoucherValidUntil('')
    setVoucherValue('')
    setClaimSuccess(false)
    setDataForm({});
  }

  return (
    <AppLayout isHomepage>
      <div className="voucher-generator">
        <div className="banner">
          <div className="container-app">
            <h1>{dataNav?.[activeNav]}</h1>
            <p>Aplikasi untuk generate dan validasi voucher Oxone</p>
          </div>
        </div>
        {role === EAdminRole.spg_voucher_all && (
          <div className="tab-selection container-app">
            {dataNav?.map((item: string, index: number) => (
              <div
                key={index}
                className={clsx("tab-item", { active: index === activeNav })}
                onClick={() => setActiveNav(index)}
              >
                <h5>{item}</h5>
              </div>
            ))}
          </div>
        )}
        <div className="form-voucher container-app">
          <Form
            form={form}
            layout="vertical"
            onFinish={() => onSubmitHandler()}
          >
            {parseContentForm()}
            <Item className="button-container">
              <Button
                className={clsx("button-app", {
                  disabled: disabledSubmitButton,
                })}
                // onClick={onSubmitHandler}
                htmlType="submit"
                loading={loadingSubmit}
                disabled={disabledSubmitButton}
              >
                {parseButtonContent}
              </Button>
              <Button
                style={{ width: '100%', marginTop: 15 }}
                // onClick={handleCloseModal}
                onClick={handlePrint}
              >
                Clear
              </Button>
            </Item>
          </Form>
        </div>
      </div>

      <div
        style={{
          display: 'none',
          // display: 'block',
        }}
      >
        <ComponentToPrint
          ref={componentRef}
          validatorName={user.name || '-'}
          phoneNumber={currentUseVoucher?.voucherGenerate.phoneNumber || '-'}
          voucherCode={currentUseVoucher?.voucherCode || '-'}
          discountValue={currentUseVoucher?.discountValue || '-'}
          total={dataForm['total'] || '-'}
        />
      </div>

      <ModalWithStatus
        isVisible={showModalGenerated}
        toggle={(state: boolean) => handleCloseModal()}
        title={parseContentModal?.title}
        state={parseContentModal?.state}
      >
        {parseContentModal?.content}
      </ModalWithStatus>

      <ModalWithStatus
        isVisible={showModalStatus}
        toggle={(state: boolean) => handleCloseModal()}
        title={isClaimSuccess ? "Sukses" : "Verifikasi kode voucher gagal"}
        state={isClaimSuccess ? "success" : "error"}
      >
        {parseContentModalStatus}
      </ModalWithStatus>
    </AppLayout>
  );
}
