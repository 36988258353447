export enum EAdminRole {
  spg_voucher_all = 'spg_voucher_all',
  spg_voucher_generate = 'spg_voucher_generate',
  spg_voucher_validate = 'spg_voucher_validate',
}

export type EAdminStatus = 
  | 'active' 
  | 'inactive'

export interface IUser {
  userId: string;
  name: string;
  email: string;
  phone: string;
  role: EAdminRole;
  status: EAdminStatus;
  updatedAt?: Date | string;
  createdAt?: Date | string;
  
  image?: File;
  profilePic?: string;
}


export const initialUser: IUser = {
  userId: '',
  name: '',
  email: '',
  phone: '',
  role: EAdminRole.spg_voucher_all,
  status: 'active',
};

export type IChangePassword = {
  oldPassword: '',
  newPassword: '',
  retypePassword: ''
}

export type AdminProperties = {
  adminId: string;
  name: string;
  email: string;
  phoneNumber: string;
  role: EAdminRole;
  status: EAdminStatus;
  createdAt?: Date;
  updatedAt?: Date;
};