import React, { useMemo, useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { Button, Form, Input, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useRouteMatch, Link, useHistory, useParams } from "react-router-dom";

import { LogoOxone } from "../../assets/images";
import "../../assets/auth.less";
import AppLayout from "../../layout/AppLayout";
import axios from 'axios';
import { IHttpResponse } from '../../helpers/pagination';
import { httpRequest } from '../../helpers/api';
import useAuth from '../../hooks/useAuth';
import { EAdminRole, IUser } from '../../types/user.type';
import { useReactToPrint } from 'react-to-print';
import PrintOut from '../printOut';

export default function Auth() {
  const { useForm, Item } = Form;
  const [form] = useForm();
  const history = useHistory()
  const route: any = useRouteMatch();
  const [dataForm, setDataForm] = useState<any>({});
  const { login, isLoading, isLoggedIn } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [resetSuccess, setResetSuccess] = useState<boolean>(false)
  const { sessionId } = useParams<{ sessionId: string }>()

  React.useEffect(() => {
    if (!isLoading && isLoggedIn) {
      history.push('/voucher-event')
    }
  }, [isLoggedIn, isLoading])

  let path: string = route?.path;

  const dataAuth = useMemo(
    () => ({
      loginForm: {
        content: [
          {
            field: "email",
            placeholder: "Email",
          },
          {
            field: "password",
            placeholder: "Password",
            type: "password",
          },
        ],
        buttonContent: "Login",
        otherLink: {
          label: "Forgot password?",
          url: "/forgot-password",
        },
      },
      forgotForm: {
        title: "Forgot Password",
        content: [
          {
            field: "email",
            placeholder: "Email",
          },
        ],
        buttonContent: "Send Verification Code",
        otherLink: {
          label: "Back to login",
          url: "/login",
        },
      },
      resetForm: {
        title: "Reset Password",
        content: [
          {
            field: "password",
            placeholder: "New Password",
            type: "password",
          },
          {
            field: "rePassword",
            placeholder: "Retype New Password",
            type: "password",
          },
        ],
        buttonContent: "Reset Password",
      },
    }),
    []
  );

  const onChangeHandler = (field: string, value: any) => {
    setDataForm({ ...dataForm, [field]: value });
  };
  const onSubmitHandler = async () => {
    setLoading(true)
    setResetSuccess(false)
    try {
      if (path?.includes("login")) {
        const res = await httpRequest.post<IHttpResponse<{ token: string }>>('/auth/signin', {
          email: dataForm['email'],
          password: dataForm['password']
        })

        const resProfile = await axios.get<IHttpResponse<IUser>>("/admin/info/me", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + res.data.payload.token,
          },
        });

        if (!resProfile.data.payload.role.includes('spg')) {
          message.error('Anda tidak punya akses untuk masuk ke aplikasi')
        } else {
          login({
            token: res.data.payload.token,
            user: resProfile.data.payload
          })
        }
      } else if (path?.includes('forgot')) {
        const res = await httpRequest.post<IHttpResponse<{ email: string, isSuccess: boolean }>>('/auth/voucher/forgot-password', {
          email: dataForm['email'],
        })

        if (res.data.payload && res.data.payload.isSuccess) {
          message.success(`Link untuk reset password sudah dikirim ke email "${dataForm['email']}". Silahkan cek email anda untuk melanjutkan reset password.`)
        }
      } else if (path?.includes('reset')) {
        const res = await httpRequest.post<IHttpResponse<{ isSuccess: boolean }>>('/auth/change-password/session', {
          sessionId,
          newPassword: dataForm['password']
        })

        if (res.data.payload && res.data.payload.isSuccess) {
          setResetSuccess(true)
          message.success(`Password Anda berhasil di ganti, Silahkan login menggunakan password baru Anda`)
        }
      }
    } catch (error) {
      console.error('error login')
    } finally {
      setLoading(false)
    }
  };

  const parseContentForm = useMemo(() => {
    let dataParsed: any = [];
    let selected: any;
    if (path?.includes("login")) {
      selected = dataAuth?.loginForm;
      dataParsed = [...dataAuth?.loginForm?.content]?.map((item) => ({
        ...item,
      }));
    } else if (path?.includes("forgot")) {
      selected = dataAuth?.forgotForm;
      dataParsed = [...dataAuth?.forgotForm?.content]?.map((item) => ({
        ...item,
      }));
    } else if (path?.includes("reset")) {
      selected = dataAuth?.resetForm;
      dataParsed = [...dataAuth?.resetForm?.content]?.map((item) => ({
        ...item,
      }));
    }
    return {
      data: selected,
      content: (
        <>
          {dataParsed?.map((item: any, index: number) => (
            <Item
              key={index}
              label={item?.label}
              className="input-container mt-2 mb-0"
            >
              {item?.type === "password" ? (
                <Input.Password
                  placeholder={item?.placeholder}
                  className="input-app"
                  value={dataForm?.[item?.field] || ""}
                  onChange={({ target: { value } }) =>
                    onChangeHandler(item?.field, value)
                  }
                />
              ) : (
                <Input
                  placeholder={item?.placeholder}
                  className="input-app"
                  prefix={
                    item?.isNumeric ? (
                      <span
                        className={clsx("text-lighten", {
                          hide: !dataForm?.[item?.field],
                          "mr-1": dataForm?.[item?.field],
                        })}
                      >
                        {item?.prefix}{" "}
                      </span>
                    ) : undefined
                  }
                  value={dataForm?.[item?.field] || ""}
                  onChange={({ target: { value } }) =>
                    onChangeHandler(item?.field, value)
                  }
                />
              )}
            </Item>
          ))}
        </>
      ),
    };
  }, [route, dataAuth, dataForm, Item]);

  const disabledSubmitButton = useMemo(() => {
    let path: string = route?.path;
    if (path?.includes("login")) {
      return !dataForm?.email || !dataForm?.password;
    } else if (path?.includes("forgot")) {
      return !dataForm?.email;
    } else if (path?.includes("reset")) {
      return !dataForm?.password || !dataForm?.rePassword;
    }
    return true;
  }, [route, dataForm]);

  useEffect(() => {
    setDataForm({});
  }, [route]);

  return (
    <AppLayout showPageHeader={false}>
      <div className="auth-app container-app h-full flex flex-col items-center">
        <LogoOxone fill="#000000" width={95} height={38} className="mt-8" />

        <div className="mt-8 content-form">
          <h1 className="m-0 font-bold text-xl leading-6">
            Oxone Voucher Event
          </h1>
          <p className="mt-2 mb-0 text-center">
            Aplikasi untuk generate dan validasi voucher Oxone
          </p>

          {parseContentForm?.data?.title && (
            <h2 className="mt-7 mb-0 font-bold text-xl leading-6">
              {parseContentForm?.data?.title}
            </h2>
          )}

          {resetSuccess ? (
            <Link
              to={'/login'}
              className="other-link mt-10 block text-center underline"
            >
              Kembali ke login
            </Link>
          ) : (
            <div className="form-auth w-full py-10">
              <Form
                form={form}
                layout="vertical"
                onFinish={onSubmitHandler}
              >
                {parseContentForm?.content}
                <Item className="button-container">
                  <Button
                    loading={loading}
                    className={clsx("button-app", {
                      disabled: disabledSubmitButton,
                    })}
                    htmlType="submit"
                    disabled={disabledSubmitButton}
                  >
                    {parseContentForm?.data?.buttonContent}
                  </Button>
                </Item>
              </Form>
              {parseContentForm?.data?.otherLink && (
                <Link
                  to={parseContentForm?.data?.otherLink?.url}
                  className="other-link mt-10 block text-center underline"
                >
                  {parseContentForm?.data?.otherLink?.label}
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  );
}
