import React, { useMemo } from "react";
import { Modal } from "antd";
import { iconSuccess, iconError, iconWarning } from "../../../assets/icons";

export default function ModalWithStatus({
  isVisible,
  toggle,
  title,
  state,
  children,
}: any) {
  const parseStatus = useMemo(() => {
    let icon;
    switch (state) {
      case "success":
        icon = iconSuccess;
        break;
      case "warning":
        icon = iconWarning;
        break;
      case "error":
        icon = iconError;
        break;
      default:
        break;
    }
    return (
      <div className="icon-status-container">
        <img src={icon} alt={`status_${state}`} />
      </div>
    );
  }, [state]);
  return (
    <Modal
      visible={isVisible}
      footer={null}
      className="modal-with-status"
      width={350}
      onCancel={toggle}
      maskClosable={false}
    >
      <div className="modal-top border-bot">
        {parseStatus}
        {title && <h4 className="title">{title}</h4>}
      </div>
      {children}
    </Modal>
  );
}
