import React, { } from 'react';
import { Button, Col, Divider, Row, Typography, Image, Card, Avatar } from 'antd';
import AppLayout from '../../layout/AppLayout';
import useAuth from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { EditOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';
import { getInitialName } from '../../helpers/constant';
import styled from 'styled-components';
import clsx from 'clsx';
import AppButton from '../../components/AppButton';
import { ChevronLeft } from 'react-feather';

const { Title, Text } = Typography;


const MyAccount: React.FC = () => {
  const { user, logout } = useAuth()
  const history = useHistory()

  return (
    <AppLayout>
      <div
        className="container-app"
      >
        <Row
          style={{
            cursor: 'pointer'
          }}
          onClick={() => history.go(-1)}
        >
          <ChevronLeft />
          <div style={{ marginLeft: 10 }}>Profil</div>
        </Row>
        <Divider style={{ marginTop: 20 }} />

        <Col
          style={{
            marginBottom: 20
          }}
        >
          <div>Nama</div>
          <div>{user.name}</div>
        </Col>

        <Divider />

        <Col
          style={{
            marginBottom: 20
          }}
        >
          <div>Email</div>
          <div>{user.email}</div>
        </Col>

        <AppButton
          type='primary'
          style={{
            width: '100%'
          }}
          onClick={logout}
        >
          Logout
        </AppButton>

      </div>
    </AppLayout>
  );

};


export default MyAccount;
