import { UserOutlined, OrderedListOutlined, LogoutOutlined } from '@ant-design/icons';
import { Avatar, Col, Divider, Menu, Modal, Row, Image } from 'antd';
import Text from 'antd/lib/typography/Text';
import Sider from 'antd/lib/layout/Sider';
import React from 'react'
import styled from 'styled-components';
import useAuth from '../hooks/useAuth';
import { useHistory, useLocation } from 'react-router-dom';
import { appVersion, getInitialName, thisYear } from '../helpers/constant';

export default function SidebarMenu() {
  const location = useLocation()
  const history = useHistory();
  const { user, logout } = useAuth();

  const menus = [
    {
      key: 'profile',
      label: 'Account',
      icon: <UserOutlined />,
      iconSelected: <UserOutlined style={{ color: '#FF4A50', fontWeight: 'bold' }} />,
    },
    {
      key: 'orders',
      label: 'My Orders',
      icon: <OrderedListOutlined />,
      iconSelected: <OrderedListOutlined style={{ color: '#FF4A50', fontWeight: 'bold' }} />,
    },
    {
      key: 'logout',
      label: 'Logout',
      icon: <LogoutOutlined />,
      iconSelected: <LogoutOutlined style={{ color: '#FF4A50', fontWeight: 'bold' }} />,
    },
  ];

  const handleClick = (e: any) => {
    if (e.key === 'logout') {
      Modal.confirm({
        title: 'Confirmation',
        content: 'Are you sure want to logout?',
        onOk: () => {
          logout();
          history.replace({ pathname: '/' });
        },
        okText: `Yes, I'm sure`,
        okType: 'primary',
      });
    } else {
      history.push('/' + e.key)
    }
  }
  return (
    <Sider
      width="22%"
      style={{ marginLeft: 50, marginTop: 20, background: 'transparent' }}
    >
      <HeaderBackground />

      <div
        style={{
          border: '1px solid lightgrey',
          borderRadius: '0 0 10px 10px',
          background: '#FFFFFF',
        }}
      >
        <Row style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
          <Col>
            {user.profilePic ? (
              <AppAvatar
                size="large"
                icon={
                  <Image
                    preview={false}
                    height={40}
                    width={40}
                    src={user.profilePic || '/images/blur-image.jpeg'}
                    fallback={'/images/blur-image.jpeg'}
                    style={{ objectFit: 'cover', borderRadius: '50%' }}
                    placeholder={
                      <Image
                        preview={false}
                        src="/images/blur-image.jpeg"
                        width={40}
                        height={40}
                      />
                    }
                  />
                }
              />
            ) : (
              <AppAvatar size="large">
                {getInitialName(user.name)}
              </AppAvatar>
            )}
          </Col>
          <Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
            <Text style={{ fontWeight: 'bold' }}>{user.name}</Text>
            <Text style={{ fontSize: 11 }}>{user.email}</Text>
          </Col>
        </Row>

        <Divider style={{ marginTop: 15, marginBottom: 5 }} />

        <Menu onClick={handleClick} mode="inline">
          {menus.map((menu) => (
            <Menu.Item
              key={menu.key}
              icon={
                location.pathname.includes('/' + menu.key)
                  ? menu.iconSelected
                  : menu.icon
              }
              style={
                location.pathname.includes('/' + menu.key)
                  ? { color: '#FF4A50', fontWeight: 'bold' }
                  : undefined
              }
            >
              {menu.label}
            </Menu.Item>
          ))}
        </Menu>
        <Row justify='center' style={{ paddingTop: 50 }}>
          <Text type='secondary' style={{ fontSize: '10px' }}>v{appVersion} · © {thisYear}. Base App </Text>
        </Row>
      </div>
    </Sider>
  );
}

const HeaderBackground = styled.div`
  background: ${({ theme }) =>
    'url(/images/bg-circle.svg) no-repeat center ' + theme.colors.primary};
  background-size: cover;
  text-align: center;
  width: 100%;
  height: 100px;
  padding-top: 100px;
  border-radius: 10px 10px 0 0;
`;

const AppAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.ash500};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 10px;
`;